import axios from 'axios'

export const spacesublime = axios.create({
    baseURL: 'https://api.globalcgi.eu/old/'
})

const instance = axios.create({
    baseURL: 'https://api.globalcgi.eu/old/'
})

export const getUser = (email) => {
    instance.post("usuarios.php", {
        email
    }).then(data => {
        console.log("usuarios")
        console.log(data)
    })
}

export const getProject = (userid) => {
    instance.post("projects.php", {
        userid
    }).then(response => {
        console.log(response.data)
        return response.data
    })
} 

export const getProyect = (userid) => {
    instance.post("proyectos.php", {
        userid
    }).then(data => {
        console.log("proyectos")
        console.log(data)
    })
}

export const getUserF = async (email) => {
    const data = await fetch("https://b2b.globalcgi.eu/test.php", 
        {
            method: "POST",
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: "jugal@codefastly.net"
        })
    })

    console.log(data)
}