import mock from '../mock'
import jwt from 'jsonwebtoken'
import { spacesublime } from "@src/utility/axios"
// import axios from 'axios'

// axios.post('https://api.spacesublime.com/register.php', {}).then(response => {
//   console.log(response)
// })
const user_permisions = {
  admin: {
    ability: [
      {
        action: 'manage',
        subject: 'all'
      }
    ],
    extras: {
      eCommerceCartItemsCount: 5,
      navColor: 'dark'
    }
  },
  client: {
    ability: [
      {
        action: 'read',
        subject: 'ACL'
      },
      {
        action: 'read',
        subject: 'Auth'
      }
    ],
    extras: {
      eCommerceCartItemsCount: 5
    }
  }
}

let data = {
  users: [
    {
      id: 1,
      fullName: 'John Doe',
      username: 'johndoe',
      password: 'admin',
      avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      email: 'admin@demo.com',
      role: 'admin',
      ability: [
        {
          action: 'manage',
          subject: 'all'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    },
    {
      id: 2,
      fullName: 'Jane Doe',
      username: 'janedoe',
      password: 'client',
      avatar: require('@src/assets/images/avatars/1-small.png').default,
      email: 'client@demo.com',
      role: 'client',
      ability: [
        {
          action: 'read',
          subject: 'ACL'
        },
        {
          action: 'read',
          subject: 'Auth'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    },
    {
      id: 3,
      fullName: 'TEST',
      username: 'test',
      password: 'admin',
      avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      email: 'test@demo.com',
      role: 'admin',
      ability: [
        {
          action: 'manage',
          subject: 'all'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    }
  ]
}

// ! These two secrets shall be in .env file and not in any other file
const jwtConfig = {
  secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
  refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
  expireTime: '10m',
  refreshTokenExpireTime: '10m'
}

mock.onPost('/jwt/login').reply(async function (request) {
  const { email, password } = JSON.parse(request.data)
  
  let error = {
    email: ['Something went wrong']
  }
  

  try {
    const api = spacesublime.post("users.php", {email})
    await api.then(response => {
      data = response.data
    })  
    let user = data.users.find(u => u.email === email && u.password === password)
    if (user.role === 'admin') user = {...user, ...user_permisions.admin}
    if (user.role === 'client') user = {...user, ...user_permisions.client}

    if (user) {
      try {
        const accessToken = jwt.sign({ id: user.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
        const refreshToken = jwt.sign({ id: user.id }, jwtConfig.refreshTokenSecret, {
          expiresIn: jwtConfig.refreshTokenExpireTime
        })

        const userData = { ...user }

        delete userData.password

        const response = {
          userData,
          accessToken,
          refreshToken
        }

        return [200, response]
      } catch (e) {
        error = e
      }
    } else {
      error = {
        email: ['Email or Password is Invalid']
      }
    }
    return [400, { error }]
    
  } catch (e) {
    error = e
    return [400, { error }]  
  }
  
})

//mock.onPost('/jwt/register').reply(request => {
mock.onPost('/jwt/register').reply(async function (request) {
  if (request.data.length > 0) {
    const { email, password, username, telefono, usertexto, type, files } = JSON.parse(request.data)

    let isEmailAlreadyInUse = data.users.find(user => user.email === email)
    const isUsernameAlreadyInUse = null
    //const isUsernameAlreadyInUse = data.users.find(user => user.username === username)

    const create_user = spacesublime.post("users.php", {email, password, username, phone : telefono, terms : true, option : 'register'})
    await create_user.then(response => {
      data = response.data
      isEmailAlreadyInUse = response.data.isEmailAlreadyInUse
    })    

    /*
    axios.post('https://api.spacesublime.com/projects.php', {...data, option: "create", user: 1}).then(response => {
      */
    const error = {
      email: isEmailAlreadyInUse ? 'This email is already in use.' : null,
      username: isUsernameAlreadyInUse ? 'This username is already in use.' : null
    }

    if (!error.username && !error.email) {
      // const userData = {
      //   email,
      //   password,
      //   username,
      //   fullName: '',
      //   avatar: null,
      //   role: 'admin',
      //   ability: [
      //     {
      //       action: 'manage',
      //       subject: 'all'
      //     }
      //   ]
      // }

      let userData = data.users.find(user => user.email === email)
      if (userData.role === 'admin') userData = {...userData, ...user_permisions.admin}
      if (userData.role === 'client') userData = {...userData, ...user_permisions.client}

      // Add user id
      // const length = data.users.length
      // let lastIndex = 0
      // if (length) {
      //   lastIndex = data.users[length - 1].id
      // }
      // userData.id = lastIndex + 1

      // data.users.push(userData)

      const accessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })

      const user = Object.assign({}, userData)
      delete user['password']
      const response = { user, accessToken }

      return [200, response]
    } else {
      return [200, { error }]
    }
  }
})

mock.onPost('/jwt/refresh-token').reply(request => {
  const { refreshToken } = JSON.parse(request.data)

  try {
    const { id } = jwt.verify(refreshToken, jwtConfig.refreshTokenSecret)

    const userData = { ...data.users.find(user => user.id === id) }

    const newAccessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn })
    const newRefreshToken = jwt.sign({ id: userData.id }, jwtConfig.refreshTokenSecret, {
      expiresIn: jwtConfig.refreshTokenExpireTime
    })

    delete userData.password
    const response = {
      userData,
      accessToken: newAccessToken,
      refreshToken: newRefreshToken
    }

    return [200, response]
  } catch (e) {
    const error = 'Invalid refresh token'
    return [401, { error }]
  }
})
